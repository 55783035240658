<template>

  <b-modal
    id="modal-cancel-booking-retail"
    v-model="isOpen"
    size="md"
    centered
    ok-only
    :ok-title="$t('golf_common_confirm')"
    ok-variant="danger"
    @ok="cancelBooking_DBKRT01"
  >
    <template #modal-header="{ close }">
      <h4 class="mb-0 text-danger">
        {{ $t('golf_booking_cancel_booking') }}
      </h4>
      <ez-icon
        icon="ezGolf-icon-x"
        class="cursor-pointer"
        size="24"
        color="#EA5455"
        @click="close"
      />
    </template>
    <b-card-body>
      <b-form>
        <div class="border-bottom pb-1 mb-1">
          <b-row>
            <b-col md="6">
              {{ $t('golf_common_first_and_last_name') }}: {{ data.MemberName }}
              <span class="text-primary font-weight-bold"></span>
            </b-col>
            <b-col md="6">
              BDC:
              <span class="text-primary font-weight-bold"># {{ data.BookingRetailCode }}
                (
                <ez-icon
                  icon="ezGolf-icon-user"
                  size="12"
                  color="#114A9F"
                />
               
                )
              </span>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col md="12">
            <b-form-group :label="$t('golf_common_reason_cancel_booking') + ':'">
              <b-form-textarea
                v-model="reason"
                :placeholder="$t('golf_common_reason_cancel_booking')"
                rows="3"
                no-resize
                maxlength="250"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="sendMail">
                <p class="mb-0">
                  {{ $t('golf_common_send_email_to_customer') }}
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { apiBookingRetail } from '@/api/booking-retail'

export default {
  name: 'CancelBooking',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ['data'],
  data() {
    return {
      reason: '',
      sendMail: false,
      isOpen: false,
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.reason = ''
        this.sendMail = false
      }
    },
  },

  methods: {
    async cancelBooking_DBKRT01() {
      const body = {
        BookingRetailId: [this.data.Id],
        SendMail: this.sendMail,
        Reason: this.reason,
      }

      const response = await apiBookingRetail.api_DBKRT01(body)
      this.showResToast(response)
      if (response.Status == 200) {
        this.$emit('event', { type: 'after-cancel-booking' })
      }
    },
  },
}
</script>

<style lang="scss">
#modal-cancel-booking-retail {
  .modal-header {
    background: rgba(234, 84, 85, 0.12) !important;
  }
}
</style>
