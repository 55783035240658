import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay, BSidebar, VBToggle, BFormRadioGroup,
} from 'bootstrap-vue'
import { booking } from '@/api/booking'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddGolfServiceRetail from '@/views/modules/ultils/AddGolfServiceRetail/AddGolfServiceRetail.vue'
import { ezToast } from '@core/utils/utils'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'
import CancelBooking from '@/views/modules/booking-retail/CancelBooking.vue'
import { apiBookingRetail } from '@/api/booking-retail'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import VSwatches from 'vue-swatches'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import 'vue-swatches/dist/vue-swatches.css'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

const dataGridRef = 'tablePackage'

export default {
  name: 'BookingRetail',
  components: {
    ToastificationContent,
    AddGolfServiceRetail,
    BForm,
    BSidebar,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    flatPickr,
    'delete-dialog': DeleteDialog,
    VSwatches,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    ShortKey,
    CancelBooking,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      userData: JSON.parse(localStorage.getItem('userData')),
      humanDate: null,
      listBookingRetail: [],
      detailCaddy: {},
      searchObj: {
        keyword: null,
        age: null,
        courseId: null,
        sex: null,
      },
      preview: null, // MB
      search: {
        BookingRetailCode: null,
        MemberName: null,
        BookingRetailDate: new Date(dayjs().startOf('day')).toISOString(),
        CashierCode: null,
        BDC: null,
      },
      data: {},
      loading: true,
      slideBar_detail_update: false,
      bookingRetailAddService: {
        Id: null,
      },
      memberDetailAddServiceRetail: {},
      isChangeMember: false,
      dataCancelBookingRetail: {
        MemberName: null,
        BookingRetailCode: null,
        Id: null,
      },
      currentPage: 1,
      perPage: 20,
      dataGridRef: 'tablePackage',
      optionPerPage: [10, 20, 50],
      configTable: [
        {
          caption: this.$t('golf_common_booking_code'),
          field: 'BookingRetailCode',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'BDC',
          field: 'Bdc',
          isShow: true,
          alignment: 'center',
          width: '8%',
        },
        {
          caption: this.$t('golf_common_first_and_last_name'),
          field: 'MemberName',
          isShow: true,
          alignment: 'center',
          width: '15%',
        },
        {
          caption: 'Thời gian',
          field: 'CreateDate',
          isShow: true,
          cellTemplate: 'create-date',
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Người tạo',
          field: 'CreateByName',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Tổng số tiền',
          field: 'ToTalPrice',
          isShow: true,
          cellTemplate: 'total-price',
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Trạng thái hóa đơn',
          field: 'PaymentStatus',
          isShow: true,
          cellTemplate: 'payment-status',
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Trạng thái',
          field: 'BookingRetailStatus',
          isShow: true,
          alignment: 'center',
          width: '10%',
        },
        {
          caption: 'Ghi chú',
          field: 'Note',
          isShow: true,
          alignment: 'center',
          width: '18%',
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
          width: '5%',
        },
      ],
      Cashiers: null,
      bdc: '',
      detailMember: '',
      allChildComponentClosed: [
        {
          ComponentId: 'modal-add-new-booking-by-bdc',
          IsActive: false,
        },
        {
          ComponentId: 'add-service-item-retail',
          IsActive: false,
        },
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12],
      isOpen: false,
      allChildComponentClosedModal: [],
      ROLE_OF_CASHIER: null,
      windowHeight: 0,
      heightTable: 400,
      listBookingRetailStatusStatistic: null,
      colorBoookingStatus: [
        {
          value: 'BOOKED',
          color: 'rgba(0, 207, 232, 0.12)',
        },
        {
          value: 'CHECK_IN',
          color: 'rgba(234, 84, 85, 0.12)',
        },
        {
          value: 'CHECK_OUT',
          color: 'rgba(136, 91, 234, 0.12)',
        },
        {
          value: 'CANCELLED',
          color: 'rgba(161, 164, 156, 0.12)',
        },
      ],
      listServiceConfigAnual: null
    }
  },
  watch: {
    isOpen(val) {
      this.SidebarOpenOrCloseHandler({ ComponentId: 'modal-add-new-booking-by-bdc', IsActive: val })
    },
    ROLE_OF_CASHIER(value) {
      this.getListCashier_RCS01(value)
    },
    windowHeight(value) {
      if(value > 600 ) {
        this.heightTable = value - 290
      }
    }
  },
  mounted() {
    this.windowHeight = window.innerHeight
  },
  created() {
    if (this.$route.query.bdc) {
      // this.teeTimeId = null
      // this.bookingDetailId = null
      // this.bookingId = null
      // this.bookingDetailprops = null
      // this.KeyWordDataProps = null

      this.search.BDC = [this.$route.query.bdc]
      // setTimeout(() => {
      //   this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
      // }, 1)
    }

    this.getListBookingRetail_RBKRT1()
    this.getRoleId()
    // this.getListCashier_RCS01()
    // this.getListCaddyCalendar_RCAC01_AC1();
    // this.callApi_RCA03();
    // this.getGroupCaddy();
  },
  computed: {
    previewImage() {
      return this.preview
    },
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  methods: {
    getListBookingRetail_RBKRT1() {
      this.loading = true
      apiBookingRetail
        .getListBookingRetail_RBKRT1(this.search)
        .then(res => {
          setTimeout(() => {
            this.$refs.listBDC.$refs.search.focus()
          }, 10)
          if (res) {
            this.data = { ...res.Data }
            this.listBookingRetail = res.Data.BookingRetail
            this.listBookingRetailStatusStatistic = res.Data.BookingRetailStatusStatistic
            // if (res.Data && res.Data.BookingRetail && res.Data.BookingRetail.length > 0) {
            //   // res.Data.BookingRetail.forEach(e => {
            //   //   this.listBookingRetail.push({ ...e })
            //   // })
            // }
            this.loading = false
          }
        })
    },
    onClick_OpenAddServiceReatil(item) {
      this.isChangeMember = true
      this.bookingRetailAddService.Id = item.Id
      this.memberDetailAddServiceRetail = {
        FullName: item.MemberName,
        FirstName: item.MemberName,
        LastName: '',
        Email: '',
        Id: item.MemberId,
        PhoneNumber: '',
        Avatar: '',
      }

      this.$root.$emit('bv::toggle::collapse', 'add-service-item-retail')
    },
    addNewBookingRetail() {
      this.isChangeMember = true
      this.bookingRetailAddService.Id = null
      this.memberDetailAddServiceRetail = {
        BDCOfBookingDetailId: null,
        FullName: 'Name Golfer',
        FirstName: 'Name Golfer',
        LastName: '',
        Email: '',
        Id: null,
        PhoneNumber: '',
        Avatar: '',
      }
      this.$root.$emit('bv::toggle::collapse', 'add-service-item-retail')
    },
    addNewBookingRetailByBDC() {
      this.$root.$emit('bv::show::modal', 'modal-add-new-booking-by-bdc')
    },
    hideModalCombineGroup() {
      // reset thông tin member sau khi đóng popup
      this.bdc = null
      this.detailMember = ''
      this.$bvModal.hide('modal-add-new-booking-by-bdc')
    },
    openSidebarAddService() {
      this.isChangeMember = true
      this.bookingRetailAddService.Id = null
      this.memberDetailAddServiceRetail = {
        BDCOfBookingDetailId: this.detailMember.Bdc,
        FullName: this.detailMember.MemberName,
        FirstName: this.detailMember.MemberName,
        LastName: this.detailMember.MemberName,
        Email: this.detailMember.Email,
        Id: this.detailMember.Id,
        PhoneNumber: this.detailMember.PhoneNumber,
        Avatar: this.detailMember.Avatar,
      }
      this.$bvModal.hide('modal-add-new-booking-by-bdc')
      this.bdc = null
      this.detailMember = ''
      this.$root.$emit('bv::toggle::collapse', 'add-service-item-retail')
    },
    handleEvent(event) {
      if (event.type === 'close_sidebar_service_retail') {
        this.getListBookingRetail_RBKRT1(true)
      }
      if (event.type === 'after-cancel-booking') {
        this.getListBookingRetail_RBKRT1(true)
      }
    },
    async getListCashier_RCS01(Id) {
      const body = {
        RoleId: [Id],
      }
      await booking.api_RCS01(body).then(response => {
        if (response.Status === '200') {
          this.Cashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    async checkBDC() {
      await booking.api_RME01F({ BDC: this.bdc }).then(res => {
        this.detailMember = res.Data.Member
      })
    },
    viewDetailBooking(item, force) {
      // Xem từ row data hoặc từ nut 3 chấm ở action
      if (item.column && item.column.name != 'action' && item.data.Id || force) {
        this.onClick_OpenAddServiceReatil(item.data)
      }
    },
    onTriggeredEventHandler(payload) {
      if (payload.key === F1) {
        this.addNewBookingRetail()
      }
      if (payload.key === F2) {
        this.addNewBookingRetailByBDC()
      }
    },
    onTriggeredEventHandlerModal(payload) {
      if (payload.key === F4) {
        this.hideModalCombineGroup()
      }
      if (payload.key === F1) {
        if (this.detailMember) {
          this.openSidebarAddService()
        } else {
          this.showToast('error', 'Mã BDC bị trống hoặc không có Golfer nào phù hợp')
        }
      }
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    async getRoleId() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.ROLE_OF_CASHIER = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_CASHIER').KeyValue)
          this.listServiceConfigAnual = res?.Data.find(item => item.KeyCode === 'BOOKING_LIST_ANNUAL_FEE').KeyValue.split(',')
        }
      })
    },
    onClick_CancelBookingRetail(data) {
      this.dataCancelBookingRetail = data
      this.$root.$emit('bv::show::modal', 'modal-cancel-booking-retail')
    },
    getStatusText(status) {
      switch (status) {
        case 'CHECK_IN':
          return 'CheckIn'
        case 'CHECK_OUT':
          return 'CheckOut'
        case 'CANCELLED':
          return 'Cancelled'
        case 'NOSHOW':
          return 'No-Show'
        default:
          return 'Booked'
      }
    },
    cellPrepared(e) {
      if (e.rowType !== 'data') { return }
      if (e.column.dataField == 'BookingRetailCode') {
        e.cellElement.style.backgroundColor = this.colorBoookingStatus.find(x => x.value == e.data.BookingRetailStatus).color
      }
    },
  },
}
